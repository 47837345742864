<template>
  <Modal
    v-model="visible"
    title="新增标签内容"
    :mask-closable="false"
    @on-cancel="returnPath">
    <Form ref="formTagsAdd" :model="TagsItem" :rules="ruleValidate" :label-width="120">
      <Form-item label="标签名" prop="TagName">
        <Input v-model="TagsItem.TagName" placeholder="请输入标签名"></Input>
      </Form-item>
    </Form>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" :loading="submitting" @click="addTags">提交</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ['data', 'show'],
  watch: {
    'show': function (newVal) {
      this.visible = newVal
    }
  },
  data () {
    return {
      permissions: {
      },
      TagsItem: {
        TagName: '',
        TagType: 1,
      },
      ruleValidate: {
        TagName: [
          { required: true, message: '标签内容不能为空', trigger: 'blur' }
        ],
      },
      submitEnabled: true,
      submitting: false,
      visible: false
    }
  },
  mounted () {
  },
  methods: {
    addTags () {
      this.$refs['formTagsAdd'].validate((valid) => {
        if (valid) {
          this.$Api.Project.addTags(this.TagsItem).then(() => {
            this.$Message.success('操作成功！')
            this.TagsItem = {
              TagName: '',
            }
            this.returnPath()
          })
          .catch(err => {
            this.submitEnabled = true
            this.submitting = false
            this.$Notice.error({
              title: '操作失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('postAdd', null)
      this.$Modal.remove()
    }
  }
}
</script>

<style>
</style>
